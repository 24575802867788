import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

export default function Testimonial() {

    const containerRefTestimonial = useRef(null);  
    const copyTestimonial = useRef(null);
    const copySignature = useRef(null);
    
    useEffect(()=>{
    
      const tl = gsap.timeline();
    
      tl.from(copyTestimonial.current, {y: -50, scrollTrigger: {
        trigger: containerRefTestimonial.current,
        start: "top 30%",
        end: "bottom 70%",
        scrub: 1.5,
      }});
  
      tl.from(copySignature.current, {xPercent: 25, scrollTrigger: {
        trigger: containerRefTestimonial.current,
        start: "top 30%",
        end: "bottom 70%",
        scrub: 1.5,
      }});
      
    }, []);

  return (
    <div className="container py-12 lg:pb-16" ref={containerRefTestimonial}>
      <div className="relative max-w-7xl mx-auto">
        <div className="relative py-24 px-8 bg-pacblue-light rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
            <StaticImage className="w-full h-full object-cover"
              src="../images/testimonial.jpg"
              width={1000}
              alt="Testimonial Image"
              placeholder="dominantColor"
            />
          </div>
          <div className="relative lg:col-span-1">
            <blockquote className="mt-6 text-white">
              <p className="text-xl font-medium sm:text-2xl" ref={copyTestimonial}>
                When we could no longer take care of Mom's needs in our home, a friend suggested we call Benevolent Senior Services.   I am so thankful for the recommendation and the opportunity for Dawn to guide us through this very emotional and stressful decision.   She has a vast knowledge of the issues/concerns finding the facility which checks all the boxes for our needs financially and medically.    Dawn's compassion and inside understanding of senior living, specifics, and different communities were vital in helping us make an informed decision.
              </p>
              <footer className="mt-6">
                <p className="text-base font-serif text-5xl text-pacblue" ref={copySignature}>
                  <span>Cindy Preston</span>
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}
