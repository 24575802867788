import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { StarIcon } from "@heroicons/react/solid"
import { gsap } from "gsap"

export default function Hero() {
  let logo = useRef(null)
  let headline1 = useRef(null)
  let headline2 = useRef(null)
  let copy = useRef(null)
  let stars = useRef(null)
  let rating = useRef(null)

  useEffect(() => {
    let tl = gsap.timeline({ defaults: { opacity: 0 } })
    tl.from(logo.current, { y: 100, delay: 1, duration: 1, ease: "none" }, "<") // bring in logo from the bottom
    tl.from(headline1.current, { x: -200, duration: 1.5, ease: "back" }) // bring in top head from left
    tl.from(headline2.current, { x: 200, duration: 1.5, ease: "back" }, "-=0.5") // bring in bottom head from right
    tl.from(copy.current, { y: -50, duration: 1, ease: "power1" }, "-=0.5") // bring copy down to meet stars
    tl.from(stars.current, { y: 50, duration: 1, ease: "power1" }, "<") // bring in stars from the bottom
    tl.from(rating.current, { y: 50, duration: 1, ease: "power1" }, "<") // bring in the copy from the bottom
  }, [])

  return (
    <main>
      <div
        id="home"
        className="bg-hero-image bg-no-repeat bg-bottom pt-10 bg-pacblue-light sm:pt-16 lg:pt-8 lg:overflow-hidden"
      >
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div className="lg:pt-18 lg:pb-24">
                <div ref={logo}>
                  <StaticImage
                    src="../images/benevolent-senior-services-logo.png"
                    width={500}
                    alt="Hero Image"
                    placeholder="blurred"
                  />
                </div>
                <h1 className="tracking-tight font-extrabold text-right text-4xl md:text-5xl xl:text-6xl pt-8">
                  <span className="block text-mnblue" ref={headline1}>
                    A better way to find
                  </span>
                  <span className="block text-engred" ref={headline2}>
                    a caring home
                  </span>
                </h1>
                <p
                  className="text-base text-mnblue sm:mt-5 lg:mt-12 sm:text-2xl text-xl"
                  ref={copy}
                >
                  Benevolent provides significant assistance in narrowing down
                  the choices and is FREE OF CHARGE. Trust the experts to
                  navigate through the journey of Senior Living with you.
                </p>
                <div className="mt-6">
                  <div className="inline-flex items-center divide-x divide-gray-300">
                    <div className="flex-shrink-0 flex pr-5" ref={stars}>
                      <StarIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                      <StarIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                      <StarIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                      <StarIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                      <StarIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      className="min-w-0 flex-1 pl-5 py-1 text-sm text-pacblue sm:py-3"
                      ref={rating}
                    >
                      <span className="font-medium text-pacblue">
                        Rated 5 stars by Visit Florida
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 lg:mb-16 sm:mb-0 lg:relative">
              <div className="mx-auto px-4 md:max-w-1xl sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                <div class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none">
                  <StaticImage
                    src="../images/hero-heart.png"
                    width={500}
                    alt="Hero Image"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
