import { graphql } from "gatsby"
import React from "react"
import About from "../components/About"
import Cards from "../components/Cards"
import Communities from "../components/Communities"
import Contact from "../components/Contact"
import FAQ from "../components/FAQ"
import Feature from "../components/Feature"
import Header1 from "../components/Header1"
import Header2 from "../components/Header2"
import Header3 from "../components/Header3"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Partners from "../components/Partners"
import Services from "../components/Services"
import SimpleMap from "../components/SimpleMap"
import Team from "../components/Team"
import Testimonial from "../components/Testimonial"
import SimpleMap2 from "../components/SimpleMap2"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <About />
      <Services />
      <Header1 />

      <div className="bg-mint py-12 lg:py-16">
        {data.bio && data.bio.nodes.length > 0 ? (
          <Team items={data.bio.nodes} />
        ) : (
          <div className="container">No bios found.</div>
        )}
      </div>

      <Feature />
      <Header2 />
      <SimpleMap />
      <Header3 />
      <SimpleMap2 />
      <Communities />
      <FAQ />
      <Testimonial />
      <Contact />

      <div className="bg-pacblue-light py-12 lg:py-16">
        {data.article && data.article.nodes.length > 0 ? (
          <Cards items={data.article.nodes} />
        ) : (
          <div className="container">No articles found.</div>
        )}
      </div>
      
      <Partners />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    article: allContentfulArticle (filter: { featured: { eq: true }}) {
      nodes {
        ...ArticleCard
      }
    }
    bio: allContentfulBio (sort: { fields: [order] order: ASC} ) {
      nodes {
        ...TeamBio
      }
    }
  }
`