import React, { useEffect, useRef } from "react"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

export default function Header2() {

  const containerRefHeader2 = useRef(null);  
  const topLine = useRef(null);
  const bottomLine = useRef(null);
  
  useEffect(()=>{
  
    const tl = gsap.timeline();
  
    tl.from(topLine.current, {x: -150, opacity:0.5, scrollTrigger: {
      trigger: containerRefHeader2.current,
      start: "top 75%",
      end: "top center",
      scrub: 1.5,
    }});

    tl.from(bottomLine.current, {x: 150, opacity:0.5, scrollTrigger: {
      trigger: containerRefHeader2.current,
      start: "top 75%",
      end: "top center",
      scrub: 1.5,
    }});
  
    // ToggleActions events: onEnter onLeave onEnterBack onLeaveBack
    // options: play, pause, resume, reset, restart, complete, reverse, none
    
  }, []);

  return (
    <div className="bg-white" ref={containerRefHeader2}>
      <div className="max-w-7xl mx-auto py-12 px-4 py-0 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-pacblue tracking-wide uppercase">BENEVOLENT SENIOR SERVICES</h2>
          <h2 className="mt-1 text-4xl font-extrabold text-mnblue sm:text-5xl sm:tracking-tight lg:text-6xl" ref={topLine}>
            We will help you choose
          </h2>
          <h2 className="mt-1 text-4xl font-extrabold text-engred sm:text-5xl sm:tracking-tight lg:text-6xl" ref={bottomLine}>
            the right home for your family
          </h2>
        </div>
      </div>
    </div>
  )
}
