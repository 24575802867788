import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { SparklesIcon } from "@heroicons/react/outline"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

export default function Example() {

  const containerRefFeature = useRef(null);  
  const topCTA = useRef(null);
  const bottomCTA = useRef(null);
  
  useEffect(()=>{
  
    const tl = gsap.timeline();
  
    tl.from(topCTA.current, {x: 150, opacity:0.5, scrollTrigger: {
      trigger: containerRefFeature.current,
      start: "top 50%",
      end: "top 10%",
      scrub: 1.5,
    }});

    tl.from(bottomCTA.current, {x: 150, opacity:0.5, scrollTrigger: {
      trigger: containerRefFeature.current,
      start: "top 60%",
      end: "bottom center",
      scrub: 1.5,
    }});
    
  }, []);

  return (
    <div className="container bg-white py-12 pb-0" ref={containerRefFeature}>
      <div className="relative max-w-7xl mx-auto">
        <div className="relative bg-white pt-16 pb-16 overflow-hidden">
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-yelloworange">
                      <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-mnblue">
                      Our years of experience provide you with professional guidance to help 
                    </h2>
                    <h2 className="text-3xl font-extrabold tracking-tight text-engred">
                     find the best care and residence
                    </h2>
                    <p className="mt-4 text-lg text-mnblue">
                      Our mission is to take the knowledge, experience and relationships we have to help navigate families through the confusion. This will eliminate the stress and help you to make the best choice in a senior living that tailored for you.
                    </p>
                    <div className="mt-6" ref={topCTA}>
                      <a
                        href="#contact"
                        className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yelloworange hover:bg-engred"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-mnblue">
                        &ldquo;Dawn is the most professional, compassionate and honest person you can work with! We found the perfect assisted living community for my mother through her invaluable support and guidance.&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="text-base font-serif text-3xl text-pacblue">Mary Carter
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
            
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <StaticImage className="t-16 w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-4/6 lg:w-auto lg:max-w-none"
                    src="../images/feature1.jpg"
                    width={750}
                    alt="Feature Image"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-yelloworange">
                      <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-mnblue">
                      Our specialists help you to ask the right questions, arrange tours, 
                    </h2>
                    <h2 className="text-3xl font-extrabold tracking-tight text-engred">
                     and negotiate the best pricing
                    </h2>
                    <div className="mt-6" ref={bottomCTA}>
                      <a
                        href="#contact"
                        className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yelloworange hover:bg-engred"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-mnblue">
                        &ldquo;I feel extremely blessed to have an experienced professional to assist me with placing my grandmother in a memory care facility. Dawn guided our family every step of the way in the daunting and emotional task of navigating through the confusing world of senior care options for our grandmother. You don't have to go through it alone.&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="text-base font-serif text-3xl text-pacblue">Kim McFarland
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="mt-4 sm:mt-4 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <StaticImage className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="../images/feature2.jpg"
                    width={750}
                    alt="Feature Image"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
