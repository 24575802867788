import PropTypes from "prop-types"
import Bio from "./Bio"
import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);


const Team = ({ items }) => {

  const containerRefTeam = useRef(null);  
  const headerTeam = useRef(null);
  const copyTeam = useRef(null);
  const icon = useRef(null);
  
  useEffect(()=>{
  
    const tl = gsap.timeline();
  
    tl.from(headerTeam.current, {y: -50, scrollTrigger: {
      trigger: containerRefTeam.current,
      start: "top 90%",
      end: "top 30%",
      scrub: 1.5,
    }});

    tl.from(copyTeam.current, {y: 250, scrollTrigger: {
      trigger: containerRefTeam.current,
      start: "top 90%",
      end: "top 30%",
      scrub: 1.5,
    }});
  
    tl.to(icon.current, {y: 1250, scrollTrigger: {
      trigger: containerRefTeam.current,
      start: "top 0%",
      end: "bottom 100%",
      scrub: 0.5,
    }});
    
  }, []);

  return (
    <div id="team">
      <div className="container bg-yelloworange-lightest py-12 lg:pb-8" ref={containerRefTeam}>
        <div className="relative max-w-7xl mx-auto">
          <div className="mx-auto md:py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-16 sm:py-0">
            <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
              <div className="space-y-5 sm:space-y-4">
                <h2 className="text-7xl font-extrabold tracking-tight font-serif tracking-tight text-yelloworange" ref={headerTeam}>Our Team</h2>
                <p className="text-xl text-mnblue" ref={copyTeam}>
                  Benevolent Senior Services bring together a unique blend of skills and experience in all facets of the
                  senior housing industry. We are committed to serving seniors' needs by finding the right home that
                  matches their health and lifestyle. We make the process of selecting senior living options effortless.
                  We rely on a compassionate, personalized approach that takes into account your unique needs and
                  requirements.
                </p>
                <div ref={icon}>
                 <StaticImage
                    src="../images/icon-gold.png"
                    width={200}
                    alt="Gold Icon"
                    placeholder="tracedSVG"
                 />
                </div>
              </div>
              <div className="lg:col-span-2">
                <ul className="space-y-12 sm:divide-y sm:divide-pacblue sm:space-y-0 sm:mt-8 lg:gap-x-8 lg:space-y-0">
                  {items.map(item => (
                      <Bio {...item} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Team.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Team
