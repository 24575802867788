import React, { useEffect, useRef } from "react"
import { SparklesIcon } from "@heroicons/react/outline"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

const features = [
  {
    name: "Discovery",
    description:
      "We meet with you and your family to discover your loved one's needs and options available to best-fit budget and location.",
    icon: SparklesIcon,
  },
  {
    name: "Research",
    description:
      "Once we understand your needs, we will recommend communities that best fit your family's needs and budget.",
    icon: SparklesIcon,
  },
  {
    name: "Scheduling",
    description:
      "We will schedule and tour communities with your family to assure all questions and concerns are asked and answered.",
    icon: SparklesIcon,
  },
  {
    name: "Follow up",
    description:
      "After a community is chosen, we will follow up within 3 days of your loved one move-in to ensure a safe transition to their new life.  Then we check in again in 30 days to make sure your loved one is happy and satisfied.",
    icon: SparklesIcon,
  },
]

export default function Example() {

const containerRef2 = useRef(null);  
const heading2 = useRef(null);

useEffect(()=>{

  const tl = gsap.timeline();

  tl.from(heading2.current, {xPercent: -15, scrollTrigger: {
    trigger: containerRef2.current,
    start: "top center",
    end:"top 30%",
    scrub: 1.5,
  }});
  
}, []);

  return (
    <div id="services" className="container bg-services-image bg-no-repeat bg-top py-6 lg:pb-16" ref={containerRef2}>
      <div className="relative max-w-7xl mx-auto">
        <div className="pb-2 bg-transparent">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center" ref={heading2}>
              <h2 className="text-center text-7xl leading-12 sm:pt-12 font-serif tracking-tight text-yelloworange">
                Our Services
              </h2>
            </div>
            <div className="mt-16 lg:mt-22">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <div
                        className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yelloworange text-white">
                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-2xl leading-6 font-bold text-pacblue">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-mnblue">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
