import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SimpleMap = (props) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-0">
        <div style={{ width: "100%" }}>
          <StaticImage
            src="../images/florida-map.png"
            width={1278}
            alt="Map of Central Florida"
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  )
}

export default SimpleMap
