import React, { useEffect, useRef } from "react"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

const faqs = [
  {
    id: 1,
    question: "Q: Does Medicare/Medicaid cover senior living?",
    answer:
      "A: Medicare does not cover the cost of senior living.  Medicaid will generally help with assisted living costs. Medicaid does not pay for the room and board for the assisted living, but only can pay for the medical portion.",
  },
  {
    id: 2,
    question: "Q: How much is the cost of monthly care?",
    answer:
      "A: The level of care differs from community to community and is determined by an assessment of your love ones care needs.",
  },
  {
    id: 3,
    question: "Q: Are veterans benefits available for long term care?",
    answer:
      "A: Yes, benefits are available.  The VA provides supplemental income through the Veterans Pension to veterans and their widowed spouses.  Then, veterans and spouses who require care and assistance may be eligible for Aid & Attendance financial benefits.  Veterans and spouses who are homebound my qualify for additional benefits.",
  },
  {
    id: 4,
    question: "Q: How much does your services cost?",
    answer:
      "A: There is no cost to the seniors and their families.   We are compensate by the senior housing community, if there is a move.",
  },
  {
    id: 5,
    question: "Q: What are some signs someone needs assisted living?",
    answer:
      "A: Decline in physical or mental health.  Your loved one has experienced one or more falls, they're forgetting to take medications, poor hygiene, not eating properly or are no longer taking an interest in their favorite activities.   Displaying signs of depression and isolation.   These are all signs that your love one may not be safe at their home any longer and would benefit from socialization, prepared meals, housekeeping and 24/7 care.",
  },
  {
    id: 6,
    question: "What are the benefits of choosing a senior care advising agency over websites that just process senior care leads?",
    answer:
      "A: The most significant difference in a company like Benevolent Senior Services is that, unlike a senior lead processing company, our Senior Advisors meet in person or via Zoom with every family and senior they work with and hold their hand throughout the entire transition. We do this to ensure that your loved one has the best possible outcome with the community that you select together. Our team can provide this service because of our unique experience working with seniors and knowledge about all aspects of senior housing.",
  },
  // More questions can be added with the same pattern.
]

export default function FAQ() {

  const containerRef6 = useRef(null);  
  const heading = useRef(null);

  useEffect(()=>{

    const tl = gsap.timeline();

    tl.from(heading.current, {xPercent: -15, scrollTrigger: {
      trigger: containerRef6.current,
      start: "top 75%",
      end:"top center",
      scrub: 1.5,
    }});
    
  }, []);

  return (
    <div id="faq" className="container bg-faq bg-no-repeat bg-center pb-12 lg:px-8" ref={containerRef6}>
      <div className="relative max-w-7xl mx-auto">
        <div className="bg-transparent">
          <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
            <div className="max-w-2xl lg:mx-auto lg:text-center">
            <div ref={heading}>
              <h2 className="text-center text-6xl leading-12 font-serif tracking-tight text-yelloworange">
                Frequently Asked Questions
              </h2>
              </div>
            </div>
            <div className="mt-20">
              <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="font-semibold text-2xl text-engred">{faq.question}</dt>
                    <dd className="mt-3 text-mnblue">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

