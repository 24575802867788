import React, { useEffect, useRef } from "react"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

export default function Header2() {

  const containerRefHeader3 = useRef(null);  
  const topLine = useRef(null);
  const bottomLine = useRef(null);
  
  useEffect(()=>{
  
    const tl = gsap.timeline();
  
    tl.from(topLine.current, {x: 150, opacity:1, scrollTrigger: {
      trigger: containerRefHeader3.current,
      start: "top 75%",
      end: "top center",
      scrub: 1.5,
    }});

    tl.from(bottomLine.current, {x: -150, opacity:1, scrollTrigger: {
      trigger: containerRefHeader3.current,
      start: "top 75%",
      end: "top center",
      scrub: 1.5,
    }});
    
  }, []);

  return (
    <div className="bg-white" ref={containerRefHeader3}>
      <div className="max-w-7xl mx-auto py-12 px-4 py-0 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="max-w-xl mt-5 mx-auto text-2xl text-pacblue">
            Tampa: Hillsborough, Pinellas, Pasco and surrounding counties
          </p>
          <h2 className="mt-1 text-4xl font-extrabold text-mnblue sm:text-5xl sm:tracking-tight lg:text-6xl" ref={topLine}>
            Serving Central Florida
          </h2>
          <p className="max-w-xl mt-5 mx-auto text-2xl text-pacblue">
            Orlando: Orange, Osceola, Brevard, Seminole and surrounding counties
          </p>
          <h2 className="mt-1 text-4xl font-extrabold text-engred sm:text-5xl sm:tracking-tight lg:text-6xl" ref={bottomLine}>
            Now Serving South Texas
          </h2>
          <p className="max-w-xl mt-5 mx-auto text-2xl text-pacblue">
            Houston: Harris, Fort Bend and surrounding counties
          </p>
        </div>
      </div>
    </div>
  )
}
