import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

export default function Communities() {

  const containerRef5 = useRef(null);  
  const heading = useRef(null);

  useEffect(()=>{

    const tl = gsap.timeline();

    tl.from(heading.current, {xPercent: -15, scrollTrigger: {
      trigger: containerRef5.current,
      start: "top 75%",
      end:"top center",
      scrub: 1.5,
    }});
    
  }, []);

  return (
    <div className="bg-mint py-12 lg:py-16">
      <div id="care" className="container py-12 lg:pb-8 bg-mint" ref={containerRef5}>
        <div className="relative max-w-7xl mx-auto">
          <div className="relative bg-gray-50 pt-16 pb-8 px-4 sm:px-6 lg:px-8">
            <div className="absolute inset-0">
              <div className="bg-white h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
              <div className="text-center">
                <div ref={heading}>
                  <h2 className="text-center text-7xl leading-8 font-serif tracking-tight text-yelloworange">
                    Communities
                  </h2>
                </div>
              </div>
              <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src='../images/comm1.jpg'
                      width={371}
                      alt="Community Image"
                      placeholder="blurred"
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-3xl font-semibold font-serif text-pacblue">Traditional Assisted Living Communities</p>
                        <p className="mt-3 text-base text-mnblue">is a home-like setting providing seniors personalized care whose health or well-being requires a higher level of support.  The most common services offered include medication management and assistance with using the bathroom, dressing, and grooming.  Housekeeping, meals, laundry, transportation services, and social programs and activities are typically included. Staff is available 24/7 to help with safety and support</p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src='../images/comm2.jpg'
                      width={371}
                      alt="Community Image"
                      placeholder="blurred"
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-3xl font-semibold font-serif text-pacblue">Independent Retirement Communities</p>
                        <p className="mt-3 text-base text-mnblue">is ideal for seniors who are looking for companionship and freedom from housekeeping, laundry, transportation, and preparing means.  These communities have the look and feel of a five star hotel offering amenities such as a gym, bistros, hair salon and lounges.</p>
                    </div>
                  </div>
                 </div> 

                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src='../images/comm3.jpg'
                      width={371}
                      alt="Community Image"
                      placeholder="blurred"
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-3xl font-semibold font-serif text-pacblue">Memory Care Facilities</p>
                        <p className="mt-3 text-base text-mnblue">support seniors living with Alzheimer\'s or dementia. They are designed to provide a safe, structured environment with set routines to lower stress for seniors diagnosed with Alzheimer\'s or dementia.  They offer programs, activities and events to help memory care residents work on their cognitive abilities.  Staff are specifically trained to provide the quality of care need for seniors with memory loss.</p>
                    </div>
                  </div>
                </div>  

                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src='../images/comm4.jpg'
                      width={371}
                      alt="Community Image"
                      placeholder="blurred"
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-3xl font-semibold font-serif text-pacblue">Respite, or Adult Daycare</p>
                        <p className="mt-3 text-base text-mnblue">is a short term care solution used to provide relief for seniors\' primary caregivers.   Respite care can be planned and scheduled out or used in an emergency situation.  (These services are provided at an assisted living community.</p>
                    </div>
                  </div>
                </div> 

                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src='../images/comm5.jpg'
                      width={371}
                      alt="Community Image"
                      placeholder="blurred"
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-3xl font-semibold font-serif text-pacblue">Residential Care Homes</p>
                        <p className="mt-3 text-base text-mnblue">are residential-style homes that accommodate a maximum of six senior residents at one time.  Adult family homes have the most flexibility in the amount of care offered, based on need. Some specialize in memory care, others focus on developmental disabilities, and others are simply comfortable with some like-minded peers and a nurse to remind you about your pills from time to time.</p>
                    </div>
                  </div>
                </div>  

                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src='../images/comm6.jpg'
                      width={371}
                      alt="Community Image"
                      placeholder="blurred"
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-3xl font-semibold font-serif text-pacblue">Other Senior Living Services</p>
                        <p className="mt-3 text-base text-mnblue">Long-Term VA Benefits, In Home Care Service, Senior Moving Companies, Elderlife Care Services, Elderly Care Attorney, Long-Term Care Medicaid, Skilled Nursing Facilities.</p>
                    </div>
                  </div>
                </div>  

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  )
}
